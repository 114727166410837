import {useEffect, useRef, useState} from 'react';
import {getKDMaxProduct} from 'service';
import {
    getDoorInfo,
    getPanelValidation,
    useQueryParams,
    getOptions,
    getCabinetFields,
} from 'shared/helpers';

import {useProductContext} from 'contexts';
import {getRequiredReferences} from 'components/customer/Product/helpers/getRequiredReferences';
import {getInitialValues} from 'components/customer/Product/helpers/getInitialValues';
import {mapInitialValues} from 'components/customer/Product/helpers/mapInitialValues';
import {getFormSchema} from 'components/customer/Product/helpers/getFormSchema';
import {useMaterialValues} from 'components/customer/Product/helpers/useMaterialValues';
import {cloneDeep} from 'lodash';
import {getMaterialRestrictionDetails} from 'components/customer/Product/helpers/getMaterialRestrictionDetails';
import {checkCabinetTopRestriction} from 'components/customer/Product/helpers/checkCabinetTopRestriction';
import {getFaceHeight} from 'components/customer/Product/Drawer/helpers';
import {useLazyGetCabinetPreviewQuery} from '../store/previewApi';

export const useProductPreviewItem = ({slug}) => {
    const {goto, height, width, depth, importid} = useQueryParams();
    const {
        setProduct,
        setInitialValuesRef,
        productDataStore,
        drawerRunnerInfoReference,
        simpleShelvesReference,
        productHasDoor,
        currentTab,
        setCurrentTab,
        formStructure,
        setFormStructure,
        validateField,
        defaultValuesReference,
        setMaterialOptions,
        setValidationData,
    } = useProductContext();
    const setMaterials = useMaterialValues();
    const [getProductData] = useLazyGetCabinetPreviewQuery();

    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [formSchema, setFormSchema] = useState({});
    const [isFavourite, setIsFavourite] = useState(false);
    const [tabErrors, setTabErrors] = useState({});
    const [productDetailCopy, setProductDetailCopy] = useState({});
    const [showRoomDefaultDialog, setShowRoomDefaultDialog] = useState(false);

    const validationRulesStore = useRef([]);
    const productValidationStore = useRef({});
    const shelvesStructureStore = useRef({});
    const shelfTypes = useRef({});
    const shelfStyles = useRef({});
    const shelfSectionHeight = useRef({});
    const partitionHeightReferenceStore = useRef();

    const initialLoad = async () => {
        // Fetch product data
        const {
            data: {
                job_cabinet: productValues,
                product_config: productConf,
                cabinet: currentProductDetail,
            },
        } = await getProductData(
            {
                slug: slug,
            },
            false
        );

        // Clone the product configuration to avoid mutation of the original data
        const ogProductConfig = {...productConf};
        const productConfig = cloneDeep(ogProductConfig);
        const productData = cloneDeep(productValues);
        const materialFields = getCabinetFields(productConfig.structure);
        const {validationData, materialOptions, materials} = setMaterials(
            productData,
            materialFields
        );

        const restrictions = getMaterialRestrictionDetails(
            materials,
            materialFields
        );
        const cabinetTopRestriction = await checkCabinetTopRestriction(
            productData
        );

        if (typeof cabinetTopRestriction !== 'undefined') {
            restrictions.push(cabinetTopRestriction);
        }

        // Ensure productDetails is mutable
        let productDetails;
        if (productData.cabinet && productData.cabinet.attributes) {
            productDetails = cloneDeep(productData.cabinet.attributes);
        } else {
            productDetails = cloneDeep(currentProductDetail); // Ensure it's mutable
        }

        // Add productConfig to productDetails
        productDetails.productConfig = productConfig.structure;

        // Fetch KDMax product if importid exists
        const kdmaxProduct = importid ? await getKDMaxProduct(importid) : null;

        if (productData && productDetails) {
            setIsFavourite(parseInt(productData.favourites) > 0);

            productDataStore.current = productData;
            validationRulesStore.current = productConfig.validation;
            productHasDoor.current = getDoorInfo(productDetails.productConfig);

            let initialValues = getInitialValues(productConfig.structure);

            ({
                drawerRunnerInfoReference: drawerRunnerInfoReference.current,
                shelfSectionHeight: shelfSectionHeight.current,
                partitionHeightReferenceStore:
                    partitionHeightReferenceStore.current,
                shelvesStructureStore: shelvesStructureStore.current,
                shelfTypes: shelfTypes.current,
                shelfStyles: shelfStyles.current,
            } = getRequiredReferences(productConfig, initialValues));

            if (!productData.hasOwnProperty('cabinet')) {
                productDataStore.current.cabinet = {
                    attributes: productDetails,
                };
            }

            productValidationStore.current = getPanelValidation(
                productDataStore.current.cabinet.attributes
            );

            setProduct(productDetails);
            setProductDetailCopy(productDetails);
            setInitialValuesRef({...initialValues}); // Keep a copy of initial values

            const shelvesOptions = getOptions(
                'simple_shelves_amount',
                productConfig.structure
            );

            // this is a fallback value from existing code with existing conditions,
            //  as we are passing on slugs it is not necessary to use this but is an requirement
            const cabinetId = 0;

            initialValues = mapInitialValues({
                initialValues,
                productData,
                shelvesStructureStore,
                simpleShelvesReference,
                partitionHeightReferenceStore,
                cabinetId,
                shelvesOptions,
                drawerRunnerInfoReference: drawerRunnerInfoReference.current,
                productConfig: productDetails.productConfig,
            });

            if (width && initialValues.hasOwnProperty('cabinet_width')) {
                initialValues.cabinet_width = width;
            }

            if (goto === 'kdmax-import') {
                initialValues.cabinet_height = height
                    ? height
                    : initialValues.cabinet_height;
                initialValues.cabinet_width = width
                    ? width
                    : initialValues.cabinet_width;
                initialValues.cabinet_depth = depth
                    ? depth
                    : initialValues.cabinet_depth;
                initialValues.cabinet_applied_panel_depth = depth
                    ? depth
                    : initialValues.cabinet_applied_panel_depth;

                if (kdmaxProduct?.shelves) {
                    initialValues.shelves = kdmaxProduct.shelves;
                    initialValues.cabinet_simple_shelves = false;
                    initialValues.simple_shelves_amount =
                        kdmaxProduct.shelfCount;
                    simpleShelvesReference.current = kdmaxProduct.shelfCount;
                }

                if (kdmaxProduct?.drawerDetails?.length) {
                    initialValues.cabinet_drawer_gap =
                        productData.cabinet_drawer_gap;
                    initialValues.cabinet_drawer_bottom =
                        kdmaxProduct.drawerDetails.bottomMargin;
                    initialValues.cabinet_drawer_top =
                        productData.cabinet_drawer_top;
                    initialValues.drawer_face_height =
                        kdmaxProduct.drawerDetails.faceDetails;
                }

                if (kdmaxProduct?.doorDetails) {
                    initialValues.cabinet_door_top =
                        kdmaxProduct.doorDetails.topMargin;
                    initialValues.cabinet_door_bottom =
                        kdmaxProduct.doorDetails.bottomMargin;
                }

                if (initialValues.hasOwnProperty('cabinet_panel_length')) {
                    initialValues.cabinet_panel_length = height;
                }

                if (initialValues.hasOwnProperty('cabinet_panel_width')) {
                    initialValues.cabinet_panel_width = width;
                }

                if (
                    productData?.cabinet?.attributes?.microwave ||
                    productData?.cabinet?.attributes?.oven
                ) {
                    let totalDrawerHeight =
                        initialValues.cabinet_total_drawer_height;

                    if (productData?.cabinet?.attributes?.microwave) {
                        totalDrawerHeight =
                            height -
                            initialValues.microwave_opening_height -
                            productData.ext_material.thickness * 2;
                    } else if (productData?.cabinet?.attributes?.oven) {
                        totalDrawerHeight =
                            height - initialValues.oven_opening_height;
                    }

                    const drawers = cloneDeep(initialValues.drawers);

                    for (let i = 0; i < drawers.length; i++) {
                        // eslint-disable-next-line security/detect-object-injection
                        if (drawers[i].id === -1) {
                            const faceHeight = getFaceHeight(
                                drawers,
                                i,
                                totalDrawerHeight,
                                initialValues.cabinet_drawer_bottom,
                                initialValues.cabinet_drawer_top,
                                initialValues.cabinet_drawer_gap,
                                'drawer_face_height',
                                'drawer_style'
                            );
                            // eslint-disable-next-line security/detect-object-injection
                            drawers[i].drawer_face_height = faceHeight;
                        }
                    }

                    initialValues.drawers = drawers;
                }

                initialValues.cabinet_note = kdmaxProduct.name;
            }

            setMaterialOptions(materialOptions);
            setValidationData({
                type: 'multiple',
                data: validationData,
            });

            defaultValuesReference.current = initialValues;
            validateField.current = true;

            if (materials) {
                if (materials.exterior && materialFields.hasExterior) {
                    initialValues.exterior_colour_name =
                        materials.exterior.name;
                }

                if (materials.carcase && materialFields.hasCarcase) {
                    initialValues.carcase_colour_name = materials.carcase.name;
                }
            }

            setInitialValues(initialValues);
            setFormSchema(
                getFormSchema(
                    productDetails.productConfig,
                    Object.keys(initialValues)
                )
            );
            setFormStructure(productConfig.structure); // Set form field structure
        }
    };

    useEffect(() => {
        if (productDetailCopy?.productConfig) {
            const newSchema = getFormSchema(
                productDetailCopy.productConfig,
                Object.keys(initialValues)
            );
            setFormSchema(newSchema);
        }
    }, [initialValues]);

    useEffect(() => {
        setLoading(true);

        initialLoad();
    }, []);

    return {
        showRoomDefaultDialog,
        setShowRoomDefaultDialog,
        currentTab,
        setCurrentTab,
        initialValues,
        formSchema,
        formStructure,
        loading,
        setLoading,
        isFavourite,
        tabErrors,
        setTabErrors,
    };
};
